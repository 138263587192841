import React, { FC, useEffect } from 'react';

// Components

// Types
import { Dependency, Subtype } from 'types/Device';

interface Props {
  dependency: Dependency;
  newEquipment: Subtype;
  setDependency: (newDep: Dependency) => void;
  setNewEquipment: (newEqu: Subtype) => void;
}

const EquipmentCombinations: FC<Props> = ({ dependency, newEquipment, setDependency, setNewEquipment }) => {
  const equipmentInUse = (eQ: Subtype) => {
    return dependency.equipmentCombinations.includes(eQ);
  };

  useEffect(() => {
    const subtypeArray = [];
    for (let key in Subtype) {
      subtypeArray.push(Subtype[key]);
    }
    setNewEquipment(Subtype[subtypeArray.find(sT => !equipmentInUse(Subtype[sT]))]);
  }, []);

  return (
    <div className="Device-Equipment-Combinations">
      <div>
        <h3 className="Device-SubCategory-Header">Equipment Combinations</h3>
      </div>
      <div className="Device-Checkboxes-Wrapper">
        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.AngularRail)}
            id="eq-cb-angularrail"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.AngularRail)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.AngularRail);
              } else {
                newEqCom.push(Subtype.AngularRail);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-angularrail">Angular Rail</label>
        </div>
        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.EOven)}
            id="eq-cb-eoven"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.EOven)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.EOven);
              } else {
                newEqCom.push(Subtype.EOven);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-eoven">E Oven</label>
        </div>
        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.EmptyingSystem)}
            id="eq-cb-emptying"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.EmptyingSystem)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.EmptyingSystem);
              } else {
                newEqCom.push(Subtype.EmptyingSystem);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-emptying">Emptying System</label>
        </div>
        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.GOven)}
            id="eq-cb-goven"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.GOven)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.GOven);
              } else {
                newEqCom.push(Subtype.GOven);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-goven">G Oven</label>
        </div>

        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.Hygiene)}
            id="eq-cb-hygiene"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.Hygiene)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.Hygiene);
              } else {
                newEqCom.push(Subtype.Hygiene);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-hygiene">Hygiene</label>
        </div>

        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.HandRail)}
            id="eq-cb-handrail"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.HandRail)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.HandRail);
              } else {
                newEqCom.push(Subtype.HandRail);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-handrail">Hand Rail</label>
        </div>
        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.HeatingCabinet)}
            id="eq-cb-cabinet"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.HeatingCabinet)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.HeatingCabinet);
              } else {
                newEqCom.push(Subtype.HeatingCabinet);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-cabinet">Heating Cabinet</label>
        </div>
        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.HeatingDrawer)}
            id="eq-cb-hdrawer"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.HeatingDrawer)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.HeatingDrawer);
              } else {
                newEqCom.push(Subtype.HeatingDrawer);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-hdrawer">Heating Drawer</label>
        </div>

        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.LowerDrawer)}
            id="eq-cb-ldrawer"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.LowerDrawer)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.LowerDrawer);
              } else {
                newEqCom.push(Subtype.LowerDrawer);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-ldrawer">Lower Drawer</label>
        </div>
        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.MixingFaucet)}
            id="eq-cb-mixing"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.MixingFaucet)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.MixingFaucet);
              } else {
                newEqCom.push(Subtype.MixingFaucet);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-mixing">Mixing Faucet</label>
        </div>

        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.Shelf)}
            id="eq-cb-shelf"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.Shelf)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.Shelf);
              } else {
                newEqCom.push(Subtype.Shelf);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-shelf">Shelf</label>
        </div>
        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.UpperDrawer)}
            id="eq-cb-udrawer"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.UpperDrawer)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.UpperDrawer);
              } else {
                newEqCom.push(Subtype.UpperDrawer);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-udrawer">Upper Drawer</label>
        </div>
        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.UpperPowerSocket)}
            id="eq-cb-upowsockt"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.UpperPowerSocket)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.UpperPowerSocket);
              } else {
                newEqCom.push(Subtype.UpperPowerSocket);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-upowsockt">Upper Power Socket</label>
        </div>
        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.WingedDoor)}
            id="eq-cb-wingd"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.WingedDoor)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.WingedDoor);
              } else {
                newEqCom.push(Subtype.WingedDoor);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-wingd">Winged Door</label>
        </div>
        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.Underframe850)}
            id="eq-cb-Underframe850"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.Underframe850)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.Underframe850);
              } else {
                newEqCom.push(Subtype.Underframe850);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-Underframe850">Underframe850</label>
        </div>
        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.Underframe580)}
            id="eq-cb-Underframe580"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.Underframe580)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.Underframe580);
              } else {
                newEqCom.push(Subtype.Underframe580);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-Underframe580">Underframe580</label>
        </div>
        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.Cooling)}
            id="eq-cb-cooling"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.Cooling)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.Cooling);
              } else {
                newEqCom.push(Subtype.Cooling);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-cooling">Cooling</label>
        </div>
        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.OpenSubstructure)}
            id="eq-cb-opensubstructure"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.OpenSubstructure)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.OpenSubstructure);
              } else {
                newEqCom.push(Subtype.OpenSubstructure);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-opensubstructure">Open Substructure</label>
        </div>
        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.WasteDrawer)}
            id="eq-cb-wastedrawer"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.WasteDrawer)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.WasteDrawer);
              } else {
                newEqCom.push(Subtype.WasteDrawer);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-wastedrawer">Waste Drawer</label>
        </div>
        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.ColdWaterInlet)}
            id="eq-cb-coldwater"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.ColdWaterInlet)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.ColdWaterInlet);
              } else {
                newEqCom.push(Subtype.ColdWaterInlet);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-coldwater">Cold Water Inlet</label>
        </div>
        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.DoubleSidedOperation)}
            id="eq-cb-double-sided-operation"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.DoubleSidedOperation)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.DoubleSidedOperation);
              } else {
                newEqCom.push(Subtype.DoubleSidedOperation);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-double-sided-operation">Double Sided Operation</label>
        </div>
        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.ShelfMixingFaucet)}
            id="eq-cb-shelfmixingfaucet"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.ShelfMixingFaucet)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.ShelfMixingFaucet);
              } else {
                newEqCom.push(Subtype.ShelfMixingFaucet);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-shelfmixingfaucet">Shelf Mixing Faucet</label>
        </div>

        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.BlindCover)}
            id="eq-cb-BlindCover"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.BlindCover)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.BlindCover);
              } else {
                newEqCom.push(Subtype.BlindCover);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-BlindCover">Blind Cover</label>
        </div>
        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.BaseCover)}
            id="eq-cb-BaseCover"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.BaseCover)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.BaseCover);
              } else {
                newEqCom.push(Subtype.BaseCover);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-BaseCover">BaseCover</label>
        </div>
        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.ReadyXpress)}
            id="eq-cb-ReadyXpress"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.ReadyXpress)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.ReadyXpress);
              } else {
                newEqCom.push(Subtype.ReadyXpress);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-ReadyXpress">ReadyXpress</label>
        </div>
        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.SpaceClean)}
            id="eq-cb-SpaceClean"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.SpaceClean)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.SpaceClean);
              } else {
                newEqCom.push(Subtype.SpaceClean);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-SpaceClean">SpaceClean</label>
        </div>
        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.MagicHood)}
            id="eq-cb-MagicHood"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.MagicHood)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.MagicHood);
              } else {
                newEqCom.push(Subtype.MagicHood);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-MagicHood">MagicHood</label>
        </div>
        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.FlexiCombiAir)}
            id="eq-cb-FlexiCombiAir"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.FlexiCombiAir)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.FlexiCombiAir);
              } else {
                newEqCom.push(Subtype.FlexiCombiAir);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-FlexiCombiAir">FlexiCombiAir</label>
        </div>

        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.GNFrame)}
            id="eq-cb-GNFrame"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.GNFrame)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.GNFrame);
              } else {
                newEqCom.push(Subtype.GNFrame);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-GNFrame">GN Gestell</label>
        </div>

        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.WarmingDrawer)}
            id="eq-cb-WarmingDrawer"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.WarmingDrawer)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.WarmingDrawer);
              } else {
                newEqCom.push(Subtype.WarmingDrawer);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-WarmingDrawer">Warmhalteschublade</label>
        </div>

        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.WarmingDevice)}
            id="eq-cb-WarmingDevice"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.WarmingDevice)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.WarmingDevice);
              } else {
                newEqCom.push(Subtype.WarmingDevice);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-WarmingDevice">Warmhaltegerät</label>
        </div>

        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.IntermediatePlate)}
            id="eq-cb-IntermediatePlate"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.IntermediatePlate)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.IntermediatePlate);
              } else {
                newEqCom.push(Subtype.IntermediatePlate);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-IntermediatePlate">Zwischenboden</label>
        </div>

        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.FlexiCombiDoor)}
            id="eq-cb-FlexiCombiDoor"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.FlexiCombiDoor)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.FlexiCombiDoor);
              } else {
                newEqCom.push(Subtype.FlexiCombiDoor);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-FlexiCombiDoor">FlexiCombiDoor</label>
        </div>
        <div className="Device-Checkboxes-Wrapper" style={{ width: '33%' }}>
          <input
            checked={equipmentInUse(Subtype.HasFixedSubstructure)}
            id="eq-cb-HasFixedSubstructure"
            onChange={() => {
              let newEqCom = [...dependency.equipmentCombinations];
              if (newEqCom.includes(Subtype.HasFixedSubstructure)) {
                newEqCom = newEqCom.filter(eq => eq !== Subtype.HasFixedSubstructure);
              } else {
                newEqCom.push(Subtype.HasFixedSubstructure);
              }
              setDependency({
                ...dependency,
                equipmentCombinations: newEqCom
              });
            }}
            type="checkbox"
          />
          <label htmlFor="eq-cb-HasFixedSubstructure">HasFixedSubstructure</label>
        </div>
      </div>
    </div>
  );
};

export default EquipmentCombinations;
