import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

// Components:
import { Button, ButtonHollow, EditProperty, LoadingSpinner } from 'components';

// Styles:
import './style.scss';
import 'react-table/react-table.css';

// Utils:
import { get, post } from 'utils/AJAX';
import { useToast } from 'components/Toast/useToast';
import { DeviceDownload } from 'page/MiscEdit/DeviceDownload';
import { DeviceUpload } from 'page/MiscEdit/DeviceUpload';

// =========================================================
interface Props extends RouteComponentProps {}
// =========================================================

const MiscEdit: FC<Props> = ({ history }) => {
  const [key, setKey] = useState('');
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [langNotFound, setLangNotFound] = useState(false);
  const [saveSuccessfull, setSaveSuccessfull] = useState(false);
  const [saving, setSaving] = useState(false);
  const { addToast } = useToast();

  // =================================

  const saveChanges = async () => {
    setSaving(true);
    const { data, error } = await post(`${process.env.REACT_APP_API_URL}/misc/save`, {
      data: { key, value }
    });
    if (data) {
      setSaveSuccessfull(true);
      addToast({
        title: 'Success',
        message: 'Misc saved',
        type: 'error',
        duration: 5000,
        id: 'save-misc-toast'
      });
    }
    if (error) {
      console.log(error);
      addToast({
        title: 'Error',
        message: "Couldn't save misc",
        type: 'error',
        duration: 5000,
        id: 'save-misc-toast'
      });
    }
    setSaving(false);
  };

  // =================================

  useEffect(() => {
    const fetchMisc = async () => {
      setLoading(true);

      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/misc/search`, {
        data: {
          rows: 100,
          offset: 0,
          query: '',
          sort: {
            field: 'key',
            order: 'ASC'
          }
        }
      });

      if (data) {
        setKey(data[0].key);
        setValue(data[0].value);
        if (langNotFound) {
          setLangNotFound(false);
        }
      }
      if (error) {
        setLangNotFound(true);
      }
      setLoading(false);
    };
    fetchMisc();
  }, [langNotFound]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (langNotFound) {
    return <div>Misc not found.</div>;
  }

  return (
    <div className="Misc-Wrapper">
      <div>
        <h1>{'Edit Misc'}</h1>
      </div>

      <EditProperty description={`${key}:`}>
        <input
          className="EditProperty-Input-Textfield"
          onChange={event => {
            setValue(event.target.value);
          }}
          placeholder="Value"
          value={value}
        />
      </EditProperty>
      <DeviceDownload />
      <DeviceUpload />

      <div className="Misc-Wrapper-ButtonContainer">
        <ButtonHollow onClick={() => history.push('/miscs')}>Cancel</ButtonHollow>

        <Button btnType="first" onClick={saveChanges}>
          Save
        </Button>
      </div>
      {saveSuccessfull && <div>Saved Changes.</div>}
      {saving && <LoadingSpinner />}
    </div>
  );
};

export default MiscEdit;
