import React, { FC, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

// Components:
import { Button, ButtonHollow, EditProperty, LoadingSpinner } from 'components';

// Styles:
import './style.css';
import 'react-table/react-table.css';

// Utils:
import { get, post } from 'utils/AJAX';
import { Right } from 'types/Member';
import { isRightInputDisabled } from 'utils/isRightInputDisabled';
import { useToast } from 'components/Toast/useToast';

const SINGLEPRICERIGHTS: { right: Right; label: string }[] = [
  { right: 'ShowSinglePricesFlexi', label: 'Show Single Prices Flexi' },
  { right: 'ShowSinglePricesSpace', label: 'Show Single Prices Combis' },
  { right: 'ShowSinglePricesMasterline', label: 'Show Single Prices Masterline' },
  { right: 'ShowSinglePricesModular', label: 'Show Single Prices Modular' },
  { right: 'ShowSinglePricesMarineMeister', label: 'Show Single Prices MarineMeister' }
];

const TOTALPRICERIGHTS: { right: Right; label: string }[] = [
  { right: 'ShowTotalPriceMasterline', label: 'Show Total Prices Masterline' },
  { right: 'ShowTotalPriceModular', label: 'Show Total Prices Modular' },
  { right: 'ShowTotalPriceMarineMeister', label: 'Show Total Prices MarineMeister' }
];

// =========================================================
interface Props extends RouteComponentProps {}
// =========================================================

const UserEdit: FC<Props> = ({ history }) => {
  const id = window.location.pathname.split('/')[2];
  const [active, setActive] = useState(false);
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(true);
  const [formValid, setFormValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordValid, setPasswordValid] = useState(true);
  const [repeatPassword, setRepeatPassword] = useState('');
  const [rights, setRights] = useState<Right[]>([]);
  const [saveSuccessfull, setSaveSuccessfull] = useState(false);
  const [userNotFound, setUserNotFound] = useState(false);
  const { addToast } = useToast();

  // =================================

  const checkPassword = (pw: string) => {
    if (pw === '') {
      return true;
    }
    return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{9,}$/g.test(pw);
  };

  const passwordsMatch = (pw: string, repeatPW: string) => pw === repeatPW;

  const isEmail = (email: string) => {
    return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(email);
  };

  const fetchUser = useCallback(async () => {
    setLoading(true);

    const { data, error } = await get(`${process.env.REACT_APP_API_URL}/member/get/${id}`);

    if (data) {
      setActive(data.active);
      setEmail(data.email);
      setRights(data.rights ? data.rights : []);
      if (userNotFound) {
        setUserNotFound(false);
      }
    }
    if (error) {
      setUserNotFound(true);
    }
    setLoading(false);
  }, [id, userNotFound]);

  const handleSendPasswordEmail = async () => {
    setLoading(true);
    const { data, error } = await post(`${process.env.REACT_APP_API_URL}/member/password/send`, {
      data: {
        id
      }
    });

    if (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const saveChanges = async () => {
    const newData =
      id === 'new'
        ? { email, active, rights, password, passwordRepeat: repeatPassword }
        : password
        ? {
            id,
            email,
            active,
            rights,
            password,
            passwordRepeat: repeatPassword
          }
        : { id, email, active, rights };
    setLoading(true);
    const { data, error } = await post(`${process.env.REACT_APP_API_URL}/member/save`, {
      data: newData
    });
    if (data) {
      setSaveSuccessfull(true);
      addToast({
        title: 'Success',
        message: 'User saved',
        type: 'success',
        duration: 5000,
        id: 'save-user-toast'
      });
    }
    if (error) {
      console.log(error);
      addToast({
        title: 'Error',
        message: "Couldn't save user",
        type: 'error',
        duration: 5000,
        id: 'save-user-toast'
      });
    }
    setLoading(false);
  };

  // ==================================================================

  useEffect(() => {
    if (id && id !== 'new') {
      fetchUser();
    }
  }, [id, fetchUser]);

  useEffect(() => {
    setEmailValid(isEmail(email));
    setPasswordValid(checkPassword(password));
    setFormValid(emailValid && ((passwordValid && passwordsMatch(password, repeatPassword)) || (repeatPassword === '' && password === '')));
  }, [email, emailValid, passwordValid, password, repeatPassword]);

  // ==================================================================

  if (loading) {
    return <LoadingSpinner />;
  }

  if (userNotFound) {
    return <div>User not found.</div>;
  }

  return (
    <div className="User-Wrapper">
      <div>
        <h1>{id === 'new' ? 'Create User' : 'Edit User'}</h1>
      </div>
      <form>
        <EditProperty description="E-Mail:">
          <input
            autoComplete="username"
            className="EditProperty-Input-Textfield"
            onChange={event => {
              setEmail(event.target.value);
            }}
            placeholder="E-Mail"
            value={email}
          />
        </EditProperty>
        <div className="Password">
          <p className="Password-Hint">
            Passwords have to be at least 9 characters long, contain lower- and uppercase letters, one digit and one special character.
          </p>
          <EditProperty description="New Password:">
            <input
              autoComplete="new-password"
              className="EditProperty-Input-Textfield"
              onChange={event => {
                setPassword(event.target.value);
              }}
              type="password"
              value={password}
            />
          </EditProperty>
          <div className="Password-Error">{password && password.length > 0 && !passwordValid && <span>Password doesn't fulfil requirements.</span>}</div>
          <EditProperty description="Repeat Password:">
            <input
              autoComplete="new-password"
              className="EditProperty-Input-Textfield"
              onChange={event => {
                setRepeatPassword(event.target.value);
              }}
              type="password"
              value={repeatPassword}
            />
          </EditProperty>
          <div className="Password-Error">
            {repeatPassword && repeatPassword.length > 0 && !passwordsMatch(password, repeatPassword) && <span>Those password don't match. Try again.</span>}
          </div>
        </div>

        <br />
        <EditProperty>
          <input checked={active} id="activeCheck" onChange={e => setActive(e.target.checked)} type="checkbox" />
          <label htmlFor="activeCheck">Active</label>
        </EditProperty>
        <EditProperty>
          <input
            checked={rights && rights.length > 0 && rights.includes('Admin')}
            id="isAdminCheck"
            onChange={e => {
              setRights(e.target.checked ? [...rights, 'Admin'] : rights.filter(v => v !== 'Admin'));
            }}
            type="checkbox"
          />
          <label htmlFor="isAdminCheck">Admin</label>
        </EditProperty>
        {TOTALPRICERIGHTS.map(({ right, label }) => (
          <EditProperty key={right}>
            <input
              checked={rights && rights.length > 0 && rights.includes(right)}
              id={right}
              onChange={e => setRights(e.target.checked ? [...rights, right] : rights.filter(v => v !== right))}
              type="checkbox"
            />
            <label htmlFor={right}>{label}</label>
          </EditProperty>
        ))}
        {SINGLEPRICERIGHTS.map(({ right, label }) => {
          const isDisabled = isRightInputDisabled(right, rights);

          return (
            <EditProperty key={right}>
              <input
                checked={rights && rights.length > 0 && rights.includes(right)}
                disabled={isDisabled}
                id={right}
                onChange={e => setRights(e.target.checked ? [...rights, right] : rights.filter(v => v !== right))}
                type="checkbox"
              />
              <label htmlFor={right}>{label}</label>
            </EditProperty>
          );
        })}
        {/*  <EditProperty>
          <input
            checked={rights && rights.length > 0 && rights.includes('ShowTotalPrice')}
            id="showTotalPriceCheck"
            onChange={e => setRights(e.target.checked ? [...rights, 'ShowTotalPrice'] : rights.filter(v => v !== 'ShowTotalPrice'))}
            type="checkbox"
          />
          <label htmlFor="showTotalPriceCheck">Total Price</label>
        </EditProperty> */}
        <EditProperty>
          <input
            checked={rights && rights.length > 0 && rights.includes('EditMasterline')}
            id="isEditMasterlineCheck"
            onChange={e => {
              setRights(e.target.checked ? [...rights, 'EditMasterline'] : rights.filter(v => v !== 'EditMasterline'));
            }}
            type="checkbox"
          />
          <label htmlFor="isEditMasterlineCheck">Edit Masterline Devices</label>
        </EditProperty>
        <EditProperty>
          <input
            checked={rights && rights.length > 0 && rights.includes('EditModular')}
            id="isEditModularCheck"
            onChange={e => {
              setRights(e.target.checked ? [...rights, 'EditModular'] : rights.filter(v => v !== 'EditModular'));
            }}
            type="checkbox"
          />
          <label htmlFor="isEditModularCheck">Edit Modular Devices</label>
        </EditProperty>
        <EditProperty>
          <input
            checked={rights && rights.length > 0 && rights.includes('AccessRoom')}
            id="isRoomAccess"
            onChange={e => {
              setRights(e.target.checked ? [...rights, 'AccessRoom'] : rights.filter(v => v !== 'AccessRoom'));
            }}
            type="checkbox"
          />
          <label htmlFor="isRoomAccess">Can Access Room</label>
        </EditProperty>
        <EditProperty>
          <input
            checked={rights && rights.length > 0 && rights.includes('AccessFlexi')}
            id="isFlexiAccess"
            onChange={e => {
              setRights(e.target.checked ? [...rights, 'AccessFlexi'] : rights.filter(v => v !== 'AccessFlexi'));
            }}
            type="checkbox"
          />
          <label htmlFor="isFlexiAccess">Can Access Flexichefs</label>
        </EditProperty>
        <EditProperty>
          <input
            checked={rights && rights.length > 0 && rights.includes('AccessSpace')}
            id="isSpaceAccess"
            onChange={e => {
              setRights(e.target.checked ? [...rights, 'AccessSpace'] : rights.filter(v => v !== 'AccessSpace'));
            }}
            type="checkbox"
          />
          <label htmlFor="isSpaceAccess">Can Access Combis</label>
        </EditProperty>
        <EditProperty>
          <input
            checked={rights && rights.length > 0 && rights.includes('AccessModular')}
            id="isModularAccess"
            onChange={e => {
              setRights(e.target.checked ? [...rights, 'AccessModular'] : rights.filter(v => v !== 'AccessModular'));
            }}
            type="checkbox"
          />
          <label htmlFor="isModularAccess">Can Access Modular</label>
        </EditProperty>
        <EditProperty>
          <input
            checked={rights && rights.length > 0 && rights.includes('AccessMasterline')}
            id="isMasterlineAccess"
            onChange={e => {
              setRights(e.target.checked ? [...rights, 'AccessMasterline'] : rights.filter(v => v !== 'AccessMasterline'));
            }}
            type="checkbox"
          />
          <label htmlFor="isMasterlineAccess">Can Access Masterline</label>
        </EditProperty>
        <EditProperty>
          <input
            checked={rights && rights.length > 0 && rights.includes('AccessMarineMeister')}
            id="isMarineMeisterAccess"
            onChange={e => {
              setRights(e.target.checked ? [...rights, 'AccessMarineMeister'] : rights.filter(v => v !== 'AccessMarineMeister'));
            }}
            type="checkbox"
          />
          <label htmlFor="isMarineMeisterAccess">Can Access MarineMeister</label>
        </EditProperty>
      </form>
      <br />
      <EditProperty>
        <ButtonHollow onClick={handleSendPasswordEmail}>Send Password Mail</ButtonHollow>
      </EditProperty>
      <div className="User-Wrapper-ButtonContainer">
        <ButtonHollow onClick={() => history.push('/users')}>Cancel</ButtonHollow>

        <Button btnType="first" onClick={saveChanges}>
          Save
        </Button>
      </div>
      {saveSuccessfull && <div className="Save-Confirm">Saved Changes.</div>}
      {loading && <LoadingSpinner />}
    </div>
  );
};

export default UserEdit;
