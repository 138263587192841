import { useEffect, useState } from 'react';
import Device from 'types/Device';

export const useGetVariantDevices = (ids: string[]) => {
  const [devices, setDevices] = useState<Device[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    Promise.all(
      ids.map(async id => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/device/get/${id}`);
        const data = await response.json();
        return data.result;
      })
    ).then(data => {
      console.log({ data });
      setDevices(data as Device[]);
      setIsLoading(false);
    });
  }, [ids]);

  return {
    isLoading,
    devices
  };
};
