import { ButtonHollow, EditProperty, LoadingSpinner } from 'components';
import { useToast } from 'components/Toast/useToast';
import React, { useState } from 'react';
import { get } from 'utils/AJAX';

export const DeviceDownload = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToast();

  const handleDownload = async () => {
    setIsLoading(true);

    const { data, error } = await get(`${process.env.REACT_APP_API_URL}/device/download/devices`, {
      timeout: 3000000,
      responseType: 'blob'
    });

    if (data) {
      // Create a Blob from the response data
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });

      // Create a download link
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'devices.xlsx'; // Set the desired file name
      a.click();

      // Clean up
      URL.revokeObjectURL(url);
    }

    if (error) {
      addToast({
        title: 'Error',
        message: "Couldn't download devices",
        type: 'error',
        duration: 5000,
        id: 'download-devices-toast'
      });
    }
    setIsLoading(false);
  };
  return (
    <EditProperty description={`Device Download`}>
      <ButtonHollow isDisabled={isLoading} onClick={handleDownload}>
        {isLoading ? 'Lädt ...' : 'Download'}
      </ButtonHollow>
    </EditProperty>
  );
};
