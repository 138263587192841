import React, { FC, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

// Components:
import { Button, ButtonHollow, EditProperty, LoadingSpinner } from 'components';

// Styles:
import 'react-table/react-table.css';
import './style.scss';

// Utils:
import Member, { Right } from 'types/Member';
import { Organization } from 'types/Organization';
import { get, post } from 'utils/AJAX';
import getUsers from 'utils/getUsers';
import { isRightInputDisabled } from 'utils/isRightInputDisabled';
import { useToast } from 'components/Toast/useToast';
import { add } from 'date-fns';

const SINGLEPRICERIGHTS: { right: Right; label: string }[] = [
  { right: 'ShowSinglePricesFlexi', label: 'Show Single Prices Flexi' },
  { right: 'ShowSinglePricesSpace', label: 'Show Single Prices Combis' },
  { right: 'ShowSinglePricesMasterline', label: 'Show Single Prices Masterline' },
  { right: 'ShowSinglePricesModular', label: 'Show Single Prices Modular' },
  { right: 'ShowSinglePricesMarineMeister', label: 'Show Single Prices MarineMeister' }
];

const TOTALPRICERIGHTS: { right: Right; label: string }[] = [
  { right: 'ShowTotalPriceMasterline', label: 'Show Total Prices Masterline' },
  { right: 'ShowTotalPriceModular', label: 'Show Total Prices Modular' },
  { right: 'ShowTotalPriceMarineMeister', label: 'Show Total Prices MarineMeister' }
];

// =========================================================
interface Props extends RouteComponentProps {}
// =========================================================

const OrganizationEdit: FC<Props> = ({ history }) => {
  const id = window.location.pathname.split('/')[2];
  const [customerNr, setCustomerNr] = useState('');
  const [discountFlexiChef, setDiscountFlexiChef] = useState(0);
  const [discountMasterline, setDiscountMasterline] = useState(0);
  const [discountModular, setDiscountModular] = useState(0);
  const [discountSpaceCombi, setDiscountSpaceCombi] = useState(0);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [orgNotFound, setOrgNotFound] = useState(false);
  const [query, setQuery] = useState('');
  const [rights, setRights] = useState<Right[]>([]);
  const [emailQuery, setEmailQuery] = useState('');
  const [salesManager, setSalesManager] = useState<string[]>([]);
  const [saveSuccessfull, setSaveSuccessfull] = useState(false);
  const [members, setMembers] = useState<Member[]>([]);
  const [userSearchStarted, setUserSearchStarted] = useState(false);
  const [usersResults, setUsersResults] = useState<Member[]>([]);
  const { addToast } = useToast();
  // =================================

  const fetchOrganization = useCallback(async () => {
    setLoading(true);

    const { data, error } = await get(`${process.env.REACT_APP_API_URL}/organization/get/${id}`);

    if (data) {
      setCustomerNr(data?.customerNr);
      setSalesManager(data?.salesManager || []);
      setRights(data?.rights || []);
      setName(data?.name || '');
      setMembers(data?.members || []);
      setDiscountFlexiChef(data?.discountFlexiChef || 0);
      setDiscountMasterline(data?.discountMasterline || 0);
      setDiscountModular(data?.discountModular || 0);
      setDiscountSpaceCombi(data?.discountSpaceCombi || 0);
      if (orgNotFound) {
        setOrgNotFound(false);
      }
    }
    if (error) {
      setOrgNotFound(true);
    }
    setLoading(false);
  }, [id, orgNotFound]);

  const searchUser = async (query: string) => {
    setUserSearchStarted(true);
    const { data, error } = await getUsers(query);
    if (data) {
      setUsersResults(data);
    }
    if (error) {
      console.log(error);
    }
  };

  const saveChanges = async () => {
    const newData: Organization =
      id === 'new'
        ? {
            customerNr,
            discountFlexiChef,
            discountMasterline,
            discountModular,
            discountSpaceCombi,
            salesManager,
            name,
            rights,
            members
          }
        : {
            id,
            discountFlexiChef,
            discountMasterline,
            discountModular,
            discountSpaceCombi,
            salesManager,
            rights,
            customerNr,
            members,
            name
          };
    setLoading(true);
    const { data, error } = await post(`${process.env.REACT_APP_API_URL}/organization/save`, {
      data: newData
    });
    if (data) {
      setSaveSuccessfull(true);
      if (id === 'new') {
        history.push(`/organizations/${data.id}`);
      }
      addToast({
        title: 'Success',
        message: 'Organization saved',
        type: 'success',
        duration: 5000,
        id: 'fetch-organization-toast'
      });
    }
    if (error) {
      console.log(error);
      addToast({
        title: 'Error',
        message: "Couldn't save organization",
        type: 'error',
        duration: 5000,
        id: 'fetch-organization-toast'
      });
    }
    setLoading(false);
  };

  // ==================================================================

  useEffect(() => {
    if (id && id !== 'new') {
      fetchOrganization();
    }
  }, [id]);

  // ==================================================================

  if (loading) {
    return <LoadingSpinner />;
  }

  if (orgNotFound) {
    return <div>Organization not found.</div>;
  }

  return (
    <div className="Organization-Wrapper">
      <div>
        <h1>{id === 'new' ? 'Create Organization' : 'Edit Organization'}</h1>
      </div>
      <EditProperty description="Name:">
        <input
          className="EditProperty-Input-Textfield"
          onChange={event => {
            setName(event.target.value);
          }}
          placeholder="Name"
          value={name}
        />
      </EditProperty>
      <EditProperty description="Customer Number:">
        <input
          className="EditProperty-Input-Textfield"
          onChange={event => {
            setCustomerNr(event.target.value);
          }}
          placeholder="Customer Number"
          value={customerNr}
        />
      </EditProperty>

      <br />
      <EditProperty description="Available Rights:">
        <input
          checked={rights && rights.length > 0 && rights.includes('Admin')}
          id="isAdminCheck"
          onChange={e => {
            setRights(e.target.checked ? [...rights, 'Admin'] : rights.filter(v => v !== 'Admin'));
          }}
          type="checkbox"
        />
        <label htmlFor="isAdminCheck">Admin</label>
      </EditProperty>
      {TOTALPRICERIGHTS.map(({ right, label }) => (
        <EditProperty key={right}>
          <input
            checked={rights && rights.length > 0 && rights.includes(right)}
            id={right}
            onChange={e => setRights(e.target.checked ? [...rights, right] : rights.filter(v => v !== right))}
            type="checkbox"
          />
          <label htmlFor={right}>{label}</label>
        </EditProperty>
      ))}
      {SINGLEPRICERIGHTS.map(({ right, label }) => {
        const isDisabled = isRightInputDisabled(right, rights);

        return (
          <EditProperty key={right}>
            <input
              checked={rights && rights.length > 0 && rights.includes(right)}
              disabled={isDisabled}
              id={right}
              onChange={e => setRights(e.target.checked ? [...rights, right] : rights.filter(v => v !== right))}
              type="checkbox"
            />
            <label htmlFor={right}>{label}</label>
          </EditProperty>
        );
      })}

      {/* <EditProperty>
        <input
          checked={rights && rights.length > 0 && rights.includes('ShowTotalPrice')}
          id="showTotalPriceCheck"
          onChange={e => setRights(e.target.checked ? [...rights, 'ShowTotalPrice'] : rights.filter(v => v !== 'ShowTotalPrice'))}
          type="checkbox"
        />
        <label htmlFor="showTotalPriceCheck">Total Price</label>
      </EditProperty> */}
      <EditProperty>
        <input
          checked={rights && rights.length > 0 && rights.includes('EditMasterline')}
          id="isEditMasterlineCheck"
          onChange={e => {
            setRights(e.target.checked ? [...rights, 'EditMasterline'] : rights.filter(v => v !== 'EditMasterline'));
          }}
          type="checkbox"
        />
        <label htmlFor="isEditMasterlineCheck">Edit Masterline Devices</label>
      </EditProperty>
      <EditProperty>
        <input
          checked={rights && rights.length > 0 && rights.includes('EditModular')}
          id="isEditModularCheck"
          onChange={e => {
            setRights(e.target.checked ? [...rights, 'EditModular'] : rights.filter(v => v !== 'EditModular'));
          }}
          type="checkbox"
        />
        <label htmlFor="isEditModularCheck">Edit Modular Devices</label>
      </EditProperty>
      <EditProperty>
        <input
          checked={rights && rights.length > 0 && rights.includes('AccessRoom')}
          id="isRoomAccess"
          onChange={e => {
            setRights(e.target.checked ? [...rights, 'AccessRoom'] : rights.filter(v => v !== 'AccessRoom'));
          }}
          type="checkbox"
        />
        <label htmlFor="isRoomAccess">Can Access Room</label>
      </EditProperty>
      <EditProperty>
        <input
          checked={rights && rights.length > 0 && rights.includes('AccessFlexi')}
          id="isFlexiAccess"
          onChange={e => {
            setRights(e.target.checked ? [...rights, 'AccessFlexi'] : rights.filter(v => v !== 'AccessFlexi'));
          }}
          type="checkbox"
        />
        <label htmlFor="isFlexiAccess">Can Access Flexichefs</label>
      </EditProperty>
      <EditProperty>
        <input
          checked={rights && rights.length > 0 && rights.includes('AccessSpace')}
          id="isSpaceAccess"
          onChange={e => {
            setRights(e.target.checked ? [...rights, 'AccessSpace'] : rights.filter(v => v !== 'AccessSpace'));
          }}
          type="checkbox"
        />
        <label htmlFor="isSpaceAccess">Can Access Combis</label>
      </EditProperty>
      <EditProperty>
        <input
          checked={rights && rights.length > 0 && rights.includes('AccessModular')}
          id="isModularAccess"
          onChange={e => {
            setRights(e.target.checked ? [...rights, 'AccessModular'] : rights.filter(v => v !== 'AccessModular'));
          }}
          type="checkbox"
        />
        <label htmlFor="isModularAccess">Can Access Modular</label>
      </EditProperty>
      <EditProperty>
        <input
          checked={rights && rights.length > 0 && rights.includes('AccessMasterline')}
          id="isMasterlineAccess"
          onChange={e => {
            setRights(e.target.checked ? [...rights, 'AccessMasterline'] : rights.filter(v => v !== 'AccessMasterline'));
          }}
          type="checkbox"
        />
        <label htmlFor="isMasterlineAccess">Can Access Masterline</label>
      </EditProperty>
      <EditProperty>
        <input
          checked={rights && rights.length > 0 && rights.includes('AccessMarineMeister')}
          id="isMarineMeisterAccess"
          onChange={e => {
            setRights(e.target.checked ? [...rights, 'AccessMarineMeister'] : rights.filter(v => v !== 'AccessMarineMeister'));
          }}
          type="checkbox"
        />
        <label htmlFor="isMarineMeisterAccess">Can Access MarineMeister</label>
      </EditProperty>
      <br />
      <EditProperty description="Members">
        <div>
          <form
            onSubmit={e => {
              e.preventDefault();
              searchUser(query);
            }}
          >
            <input
              className="EditProperty-Input-Textfield"
              onChange={event => {
                setQuery(event.target.value);
              }}
              placeholder="Add Member"
              value={query}
            />
          </form>
          {userSearchStarted && (
            <div>
              <h4>Results</h4>
              <div>
                {usersResults && usersResults.length > 0
                  ? usersResults.map(cR => (
                      <button
                        key={`Add-Component-Button-${cR.id}`}
                        onClick={e => {
                          e.preventDefault();
                          setMembers([...members, cR]);
                        }}
                      >
                        {cR.email}
                      </button>
                    ))
                  : usersResults.length === 0 && <div>No User found.</div>}
              </div>
            </div>
          )}
          <div>
            <h4>Added Members</h4>
            {members &&
              members.length > 0 &&
              members.map((s, i) => (
                <div key={i}>
                  <span>{s.email}</span> {' - '} Superuser:{' '}
                  <input
                    checked={s.supervisor}
                    id={`supervisor-${s.id}`}
                    onChange={() => {
                      const newMembers = [...members];
                      newMembers.splice(i, 1, { ...s, supervisor: !s.supervisor });
                      setMembers(newMembers);
                    }}
                    type="checkbox"
                  />
                  {'-  '}
                  <button onClick={() => setMembers(members.filter(curr => curr.email !== s.email))}>Remove</button>
                </div>
              ))}
          </div>
        </div>
      </EditProperty>
      <br />
      <EditProperty description="E-Mail List">
        <div>
          <form
            onSubmit={e => {
              e.preventDefault();
              setSalesManager([...salesManager, emailQuery]);
              setEmailQuery('');
            }}
          >
            <input
              autoComplete="email"
              className="EditProperty-Input-Textfield"
              onChange={event => {
                setEmailQuery(event.target.value);
              }}
              placeholder="E-Mail"
              value={emailQuery}
            />
          </form>

          <div>
            <h4>E-Mail List</h4>
            {salesManager &&
              salesManager.length > 0 &&
              salesManager.map((s, i) => (
                <div key={i}>
                  <span>{s}</span>
                  <button onClick={() => setSalesManager(salesManager.filter(curr => curr !== s))}>Remove</button>
                </div>
              ))}
          </div>
        </div>
      </EditProperty>
      <br />

      <EditProperty description="Organization Discounts">
        {/* <div>
          <label className="discount-label">
            <input
              className="discount-input"
              min={0}
              max={100}
              onBlur={e => {
                const val = parseFloat(e.target.value);
                if (val > 100) setDiscountFlexiChef(100);
                if (val < 0) setDiscountFlexiChef(0);
              }}
              onChange={e => {
                setDiscountFlexiChef(parseFloat(e.target.value));
              }}
              step={0.1}
              type="number"
              value={discountFlexiChef}
            />
            Discount FlexiChef (%)
          </label>
        </div> */}
        <div>
          <label className="discount-label">
            <input
              className="discount-input"
              min={0}
              max={100}
              onBlur={e => {
                const val = parseFloat(e.target.value);
                if (val > 100) setDiscountMasterline(100);
                if (val < 0) setDiscountMasterline(0);
              }}
              onChange={e => {
                setDiscountMasterline(parseFloat(e.target.value));
              }}
              step={0.1}
              type="number"
              value={discountMasterline}
            />
            Discount Masterline (%)
          </label>
        </div>
        <div>
          <label className="discount-label">
            <input
              className="discount-input"
              min={0}
              max={100}
              onBlur={e => {
                const val = parseFloat(e.target.value);
                if (val > 100) setDiscountModular(100);
                if (val < 0) setDiscountModular(0);
              }}
              onChange={e => {
                setDiscountModular(parseFloat(e.target.value));
              }}
              step={0.1}
              type="number"
              value={discountModular}
            />
            Discount Modular (%)
          </label>
        </div>
        <div>
          <label className="discount-label">
            <input
              className="discount-input"
              min={0}
              max={100}
              onBlur={e => {
                const val = parseFloat(e.target.value);
                if (val > 100) setDiscountSpaceCombi(100);
                if (val < 0) setDiscountSpaceCombi(0);
              }}
              onChange={e => {
                setDiscountSpaceCombi(parseFloat(e.target.value));
              }}
              step={0.1}
              type="number"
              value={discountSpaceCombi}
            />
            Discount SpaceCombi (%)
          </label>
        </div>
      </EditProperty>

      <div className="Organization-Wrapper-ButtonContainer">
        <ButtonHollow onClick={() => history.push('/organizations')}>Cancel</ButtonHollow>

        <Button btnType="first" onClick={saveChanges}>
          Save
        </Button>
      </div>
      {saveSuccessfull && <div className="Save-Confirm">Saved Changes.</div>}
      {loading && <LoadingSpinner />}
    </div>
  );
};

export default OrganizationEdit;
